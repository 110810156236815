import React from "react";
import "./loading.css";

function Loading(props) {
  return (
    <div className="wrapper">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
      <span>Loading ...</span>
    </div>
  );
}

export default Loading;
