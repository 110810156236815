import "./App.css";
import Features from "./Components/Features/Features";
import Header from "./Components/Header/Header";
import Landing from "./Components/Landing/Landing";
import About from "./Components/About/About";
import Contant from "./Components/Contant/Contant";
import Footer from "./Components/Footer/Footer";
import WorkSteps from "./Components/WorkSteps/WorkSteps";
import { BrowserRouter } from "react-router-dom";
import { useState } from "react";
import Loading from "./loading";
import Service from "./Components/Service/Service";
import ScrollArrow from "./ScrollArrow";

function App() {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 3000);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <BrowserRouter>
          <div className="">
            <Header />
            <Landing />
            <About />
            <Features />
            <Service />
            <WorkSteps />
            <Contant />
            <Footer />
            <ScrollArrow />
          </div>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
