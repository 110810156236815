import React from "react";
import "./Footer.css";
import "../../App.css";

function Footer(props) {
  return (
    <div className="footer">
      &copy; 2021 <span> 10Code </span> All Right Reserved
    </div>
  );
}

export default Footer;
