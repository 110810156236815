import "../../App.css";
import "./Header.css";
import logo from "../../image/10CODE logo.png";

function Header() {
  return (
    <div>
      <div className="header" id="header">
        <div className="container">
          <a href="#" className="logo">
            <img src={logo} alt={""} className="header-logo" />
          </a>
          <ul className="main-nav">
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#features">Features</a>
            </li>
            <li>
              <a href="#service">Service</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
