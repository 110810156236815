import React from "react";
import "../../App.css";
import "./Landing.css";
import Landingimg from "../../image/Landing.png";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

function Landing() {
  return (
    <div className="landing">
      <Fade cascade>
        <div className="container">
          <div className="text">
            {/* <Flip cascade> */}
            <Flip left cascade>
              <h1>Welcome, To 10Code World</h1>
              {/* </Flip> */}
              <p>
                10code is a software development agency that specializes in
                creating highly-usable mobile applications for iOS and Android,
                as well as web applications. We use the latest and greatest
                technology in combination with an optimized agile approach. We
                have grown from a couple of friends to a structured medium-sized
                tech agency. Our team is a bunch of highly passionate designers
                and developers who work hand in hand to deliver the experience
                you long for.
              </p>
            </Flip>
          </div>
          <div className="image">
            <img src={Landingimg} alt="" />
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Landing;
