import React from "react";
import "../../App.css";
import "./Features.css";
import Fade from "react-reveal/Fade";

function Features(props) {
  return (
    <div className="features" id="features">
      <Fade right cascade>
        <h2 className="special-heading">Features</h2>
        <p>Don't be busy, be productive</p>
        <div className="container">
          <div className="box">
            <i class="fas fa-code fa-2x"></i>
            <div className="text">
              <h3>Web Development</h3>
              <p>
                We provide custom web software development services for
                medium-sized enterprises and small businesses. We provide both
                frontend and backend development services.
              </p>
            </div>
          </div>
          <div className="box">
            <i className="fas fa-mobile-alt fa-2x"></i>
            <div className="text">
              <h3>Apps Development</h3>
              <p>
                Our priority as a mobile app development company is to design
                mobile and strategic applications which enable you to complete
                in the market and expand your business.
              </p>
            </div>
          </div>
          <div className="box">
            <i className="fas fa-project-diagram fa-2x"></i>
            <div className="text">
              <h3>Project Management</h3>
              <p>
                Keep your project moving forward smoothly with a dedicated
                project manager to ensure that all your expectations and
                requirements are met, and make sure that your project is
                delivered on schedule.
              </p>
            </div>
          </div>
          <div className="box">
            <i className="fas fa-vector-square fa-2x"></i>
            <div className="text">
              <h3>UI & UX</h3>
              <p>
                We offer prototyping, branding, UI and UX design for web and
                mobile products. Our approach is simple: we focus on how actual
                people will use our products.
              </p>
            </div>
          </div>
          <div className="box">
            <i className="fas fa-quote-left fa-2x"></i>
            <div className="text">
              <h3>Quality Assurance</h3>
              <p>
                We apply quality assurance and testing services to all software
                we write, checking the quality of mobile and web apps as well as
                server-side software.
              </p>
            </div>
          </div>
          <div className="box">
            <i className="fas fa-magic fa-2x"></i>
            <div className="text">
              <h3>Digital Marketing</h3>
              <p>
                Everything is going digital , and so should your marketing . we
                will help you to make others know you are there !!
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Features;
