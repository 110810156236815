import React from "react";
import "./About.css";
import "../../App.css";
import aboutUS from "../../image/about.jpg";
import Fade from "react-reveal/Fade";

function About(props) {
  return (
    <div className="about" id="about">
      <Fade left cascade>
        <div className="container">
          <h2 className="special-heading">About</h2>
          <p>Less is more work</p>
          <div className="about-content">
            <div className="image">
              <img src={aboutUS} alt="" />
            </div>
            <div className="text">
              <p>
                Software development and web development Company based in saudia
                arabia. Quartered in Dammam we are providing IT solutions and
                IT-enabled services. Ever since our establishment, we are
                growing every day to meet the growing demands of our clients and
                surpass their expectations.
              </p>
              <hr />
              <p>
                Our expertise in providing industry-specific services and custom
                solutions has enabled us to deliver absolute business solutions
                to our worldwide clients. We are providing our services to small
                as well as big corporate houses including individual
                entrepreneurs using our unmatched expertise and cutting edge
                technology.
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default About;
