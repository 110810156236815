import React from "react";
import "./Contant.css";
import "../../App.css";
import Fade from "react-reveal/Fade";

function Contant(props) {
  return (
    <div className="contact" id="contact">
      <Fade cascade>
        <div className="container">
          <h2 className="special-heading">Contact</h2>
          <p>We are born to create</p>
          <div className="info">
            <p className="label">Feel free to drop us a line at:</p>
            <a href="https://mail.google.com" target="_blank" className="link">
              <i className="fas fa-envelope"></i> info@10code.net
            </a>
            <br />
            <div className="link">
              <i className="fas fa-mobile-alt"></i> +966 50 144 3190
            </div>
            <div className="social">
              Find Us On Social Networks
              <i className="fab fa-instagram instagram"></i>
              <i className="fab fa-facebook-f facebook"></i>
              <i className="fab fa-twitter twitter"></i>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Contant;
