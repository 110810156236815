import React from "react";
import "./WorkSteps.css";
import "../../App.css";
import Work from "../../image/work-steps.png";
import Fade from "react-reveal/Fade";

function WorkSteps(props) {
  return (
    <div className="work-steps" id="work-steps">
      <Fade right cascade>
        <div className="">
          <h2 className="special-heading">How It Works ?</h2>
          <p>Processes to works project</p>
        </div>
        <div className="container mt-5">
          <img src={Work} alt="" className="image" />
          <div className="info">
            <div className="box">
              {/* <img src="imgs/work-steps-1.png" alt="" /> */}
              <div className="text">
                <h3>Business Analysis</h3>
                <p>
                  We close the gap in understanding between enterprise
                  management and the complex data and technical systems that
                  companies use in hopes of improving operations and helping
                  influence decision-making.
                </p>
              </div>
            </div>
            <div className="box">
              {/* <img src="imgs/work-steps-2.png" alt="" /> */}
              <div className="text">
                <h3>Architecture</h3>
                <p>
                  Information Architecture (IA) We organize and structure the
                  content of websites, web applications, mobile and social media
                  software. ...information engineering has become the primary
                  study in many fields including design and software development
                </p>
              </div>
            </div>
            <div className="box">
              {/* <img src="imgs/work-steps-3.png" alt="" /> */}
              <div className="text">
                <h3>Development</h3>
                <p>
                  We develop software is the process of conceiving, identifying,
                  designing, programming, documenting, testing and fixing errors
                  that go into creating and maintaining applications, frameworks
                  or other software components. Software development involves
                  writing and maintaining the source code, but in a broader
                  sense, it includes all the processes from the concept of the
                  required software to the final appearance of the software,
                  usually in a planned and organized process
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default WorkSteps;
