import React from "react";
import "../../App.css";
import "./Service.css";
import webDev from "../../image/web-dev.jpeg";
import appDev from "../../image/app-dev.jpeg";
import deg from "../../image/deg-mar.jpeg";
import proj from "../../image/proj-ma.jpeg";
import qa from "../../image/qa.jpeg";
import uiux from "../../image/ux-ui.jpeg";
import Fade from "react-reveal/Fade";

function WhatWeDo(props) {
  return (
    <div className="service" id="service">
      <Fade left cascade>
        <h2 className="special-heading">Services</h2>
        <p>Brilliant Ideas.Executed For Better Business.</p>
        <div className="container mt-5">
          <div className="box quality">
            <div className="img-holder">
              <img src={webDev} alt="" />
            </div>
            <h3>Best Services</h3>
            <p>
              Your business is important to us. We provide amazing service that
              you deserve.
            </p>
          </div>
          <div className="box quality">
            <div className="img-holder">
              <img src={appDev} alt="" />
            </div>
            <h3>Daily Scrums</h3>
            <p>
              We have a Daily Standup with our developers to assist in meeting
              our project goals and timelines
            </p>
          </div>
          <div className="box quality">
            <div className="img-holder">
              <img src={uiux} alt="" />
            </div>
            <h3>Developer Support</h3>
            <p>
              You have an update after release? Our developers that can support
              your request.
            </p>
          </div>
          <div className="box quality">
            <div className="img-holder">
              <img src={deg} alt="" />
            </div>
            <h3>Timely Delivery</h3>
            <p>
              Meeting project deadlines. Delivering your project ontime is
              always our #1 goal.
            </p>
          </div>
          <div className="box quality">
            <div className="img-holder">
              <img src={qa} alt="" />
            </div>
            <h3>Agile Practices</h3>
            <p>
              We use Agile Methdologies to assist us meeting customer and
              developer needs.
            </p>
          </div>
          <div className="box quality">
            <div className="img-holder">
              <img src={proj} alt="" />
            </div>
            <h3>NDA Protection</h3>
            <p>
              Your ideas are important to us. We have a NDA to ensure your work
              is protected.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default WhatWeDo;
